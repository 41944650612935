<template>
    <v-row>
        <v-dialog v-model="dialogAguardando" hide-overlay persistent max-width="575">
            <v-card color="primary" dark>
                <v-card-text class="pa-5">
                    Seu pedido está em análise de credito, assim que a análise for concluída você será redirecionado
                    para a tela de acompanhamento do pedido.
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemover.status" persistent max-width="575">
            <v-card tile class="pa-5">
                <div class="text-center pt-5">
                    <v-icon class="text-h1" color="blue-grey lighten-2">mdi-help-circle-outline</v-icon>
                </div>
                <v-card-title class="text-h5">
                    <span class="mx-auto">Exclusão de produtos</span>
                </v-card-title>
                <v-card-text
                    class="text-center text-subtitle-1"
                >Deseja remover apenas este produto ou todos os produtos com o mesmo código?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        dark
                        class="px-5"
                        large
                        elevation="0"
                        @click="removerProdutoPedido(dialogRemover.produto)"
                    >Apenas este</v-btn>
                    <v-btn
                        color="primary"
                        class="px-5"
                        large
                        elevation="0"
                        @click="limparPedido()"
                    >Todos produtos</v-btn>
                    <v-btn
                        color="red"
                        dark
                        class="px-5"
                        large
                        elevation="0"
                        @click="dialogRemover.status = false;"
                    >Cancelar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="7" lg="8" order="2" order-md="1">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="5" class="text-truncate">Lista de equipamentos</v-col>
                        <v-spacer />
                        <v-col cols="12" sm="6" md="3" class="pl-md-1">
                            <v-text-field
                                v-mask="'##.##'"
                                v-model="descontoGeral"
                                dense
                                outlined
                                label="Desconto"
                                @click:append="'#####'"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th>Equipamento</th>
                                <th>Quantidade</th>
                                <th>Prazo</th>
                                <th>Valor</th>
                                <th>Desconto</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="produtos.length > 0">
                                <tr v-for="(item, i) in produtos" :key="i">
                                    <td>
                                        <v-btn
                                            x-small
                                            color="red"
                                            dark
                                            elevation="0"
                                            fab
                                            @click="dialogRemover.status = true, dialogRemover.produto = item"
                                        >
                                            <v-icon>mdi-minus</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-truncate">1010</td>
                                    <td class="text-truncate">#### ##### ######</td>
                                    <td class="text-center text-truncate">
                                        <v-text-field
                                            style="min-width: 100px"
                                            type="number"
                                            reverse
                                            dense
                                            class="mb-n4"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-truncate">
                                        <v-select
                                            style="min-width: 100px"
                                            class="mb-n4"
                                            dense
                                            :items="['Diária', 'Semanal', 'Quinzenal', 'Mensal']"
                                        />
                                    </td>
                                    <td class="text-truncate">R$ ###,##</td>
                                    <td class="text-truncate">R$ ##,##</td>
                                    <td class="text-truncate">R$ ###,##</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td
                                        class="text-center"
                                        colspan="8"
                                    >Nenhum produto adicionado ao pedido</td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="5" lg="4" order="1" order-md="2">
            <v-card>
                <v-card-title>* Resumo do pedido</v-card-title>
                <v-card-text v-if="produtos.length > 0">
                    <v-stepper v-model="step" vertical flat>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                            class="px-0 py-3 font-weight-bold"
                        >Resumo dos produtos</v-stepper-step>
                        <v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-cart-arrow-down</v-icon>10 Equipamentos
                                    </div>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-sale</v-icon>Desconto
                                    </div>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-cart-check</v-icon>Total
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="text-right">
                                    <div class="py-2 text-body-1 font-weight-bold">R$ 300,00</div>
                                    <div class="py-2 text-body-1 font-weight-bold">R$ 30,00</div>
                                    <div class="py-2 text-body-1 font-weight-bold">R$ 270,00</div>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" small @click="step = 2">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                            class="px-0 py-3 font-weight-bold"
                        >Dados do cliente</v-stepper-step>
                        <v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Cliente</div>
                                    <v-autocomplete
                                        no-filter
                                        v-model="pedido.cliente"
                                        outlined
                                        dense
                                        :search-input.sync="search"
                                        :items="items"
                                        :loading="carregando"
                                        item-text="nome"
                                        item-value="idenome"
                                        class="mb-n10"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Endereço de entrega</div>
                                    <v-select
                                        class="mb-n10"
                                        :items="['Endereço de entrega']"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 1">Voltar</v-btn>
                                    <v-btn small color="primary" @click="step = 3">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                            class="px-0 py-3 font-weight-bold"
                        >Dados de pagamento</v-stepper-step>
                        <v-stepper-content step="3" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Forma de pagamento</div>
                                    <v-select
                                        class="mb-n10"
                                        :items="['Dinheiro', 'Cartão débito', 'Cartão crédito']"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Condição de pagamento</div>
                                    <v-select
                                        class="mb-n10"
                                        :items="['à vista', '30 dias', '60 dias', '90 dias']"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 2">Voltar</v-btn>
                                    <v-btn small color="primary" @click="step = 4">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step
                            :complete="step > 4"
                            step="4"
                            class="px-0 py-3 font-weight-bold"
                        >Dados complementares</v-stepper-step>
                        <v-stepper-content step="4" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Data de início</div>
                                    <InputDatePicker
                                        v-model="pedido.dataInicio"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Observação</div>
                                    <v-textarea class="mb-n10" outlined rows="1"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Vendedor</div>
                                    <v-select class="mb-n10" :items="['Vendedor']" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 3">Voltar</v-btn>
                                    <v-btn small color="primary" @click="step = 5">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-step
                            :complete="step > 5"
                            step="5"
                            class="px-0 py-3 font-weight-bold"
                        >Confirmação do pedido</v-stepper-step>
                        <v-stepper-content step="5" class="px-0 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="5">
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Cliente</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Endereço</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Forma de pagamento</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Condição de pagamento</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Data início</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Vendedor</div>

                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >10 Equipamentos</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Desconto</div>
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >Total</div>
                                </v-col>
                                <v-col cols="7" class="text-right">
                                    <div class="py-0 text-caption text-truncate">{{pedido.cliente}}</div>
                                    <div
                                        class="py-0 text-caption text-truncate"
                                    >Rua José Inacio, nº 150, Centro - Ivaiporã-PR</div>
                                    <div class="py-0 text-caption text-truncate">Dinheiro</div>
                                    <div class="py-0 text-caption text-truncate">à vista</div>
                                    <div class="py-0 text-caption text-truncate">30/04/2022</div>
                                    <div class="py-0 text-caption text-truncate">Edson Maia</div>
                                    <div class="py-0 text-caption text-truncate">R$ 300,00</div>
                                    <div class="py-0 text-caption text-truncate">R$ 30,00</div>
                                    <div class="py-0 text-caption text-truncate">R$ 270,00</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        small
                                        text
                                        block
                                        color="primary"
                                        class="mb-1"
                                        @click="step = 4"
                                    >Voltar</v-btn>
                                    <v-btn
                                        :disabled="produtos.length == 0"
                                        color="primary"
                                        block
                                        small
                                        elevation="0"
                                        @click="finalizarPedido()"
                                    >Finalizar</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper>
                </v-card-text>
                <v-card-actions class="pa-4 mt-n10">
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                class="text-decoration-underline"
                                block
                                elevation="0"
                                color="primary"
                                to="/aluguel-equipamento"
                                text
                                small
                                exact
                            >Adicionar mais equipamentos</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import InputDatePicker from "../Widgets/InputDatePicker";
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "AluguelEquipamentoFaturar",
    components: { InputDatePicker },
    data: () => ({
        step: 1,
        carregando: false,
        produtos: [],
        dialogRemover: { status: false, produto: {} },
        dialogAguardando: false,
        descontoGeral: "",
        pedido: {
            cliente: "",
            dataInicio: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getDate() < 10
                    ? "0"
                    : "" +
                      new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          0
                      ).getDate()
            }`,
        },
        aguardarBusca: "",
        nomeLimit: 50,
        clientes: [],
        search: null,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
        items() {
            if (this.clientes == null) return;

            this.clientes.forEach((cliente) => {
                if (cliente.nome.length > this.nomeLimit) {
                    cliente.nome =
                        cliente.nome.slice(0, this.nomeLimit) + "...";
                }
            });

            return this.clientes;
        },
    },
    methods: {
        limparPedido() {
            this.produtos = [];
            localStorage.removeItem("aluguel");
            this.alerta = false;
            this.dialogRemover.status = false;
        },
        removerProdutoPedido(produto) {
            this.produtos.splice(this.produtos.indexOf(produto), 1);
            localStorage.setItem("aluguel", JSON.stringify(this.produtos));
            this.dialogRemover.status = false;
        },
        finalizarPedido() {
            this.dialogAguardando = true;
            //localStorage.removeItem("aluguel");
            setTimeout(
                () => (
                    (this.dialogAguardando = false),
                    this.$router.push("/aluguel-equipamento/get/1")
                ),
                5000
            );
        },
        carregarClientes(val) {
            clearTimeout(this.aguardarBusca);

            this.aguardarBusca = setTimeout(() => {
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}cliente/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                    })
                    .then((res) => {
                        this.clientes = res.data.lista.map((v) => {
                            return {
                                ...v,
                                idenome: `${v.idcliente}-${v.nome}`,
                            };
                        });
                        this.carregando = false;
                    })
                    .catch(() => {
                        this.carregando = false;
                    });
            }, this.timeLimit);
        },
    },
    watch: {
        search: function (val) {
            this.carregarClientes(val);
        },
    },
    created() {
        const json = localStorage.getItem("aluguel");
        const produtos = JSON.parse(json);

        if (produtos) {
            this.produtos = produtos;
        }
    },
};
</script>

<style scoped>
</style>