<template>
	<v-menu :close-on-content-click="false" v-model="menu" max-width="290px">
		<template v-slot:activator="{ on }">
			<v-text-field
				:prefix="prefix"
				:persistent-hint="persistentHint"
				:flat="flat"
				:dense="dense"
				:disabled="disabled"
				:clearable="clearable"
				@click:clear="updateValue(null)"
				:hide-details="hideDetails"
				v-on="on"
				:outlined="outlined"
				:solo-inverted="soloInverted"
				:label="label"
				v-model="dataInternaPt"
				readonly
			/>
		</template>
		<v-date-picker
			:allowed-dates="allowedDates"
			:type="type"
			show-current
			@input="updateValue"
			v-model="dataInternaEn"
		/>
	</v-menu>
</template>

<script>
export default {
	name: "InputDatePicker",
	props: [
		"label",
		"value",
		"flat",
		"hideDetails",
		"outlined",
		"soloInverted",
		"dense",
		"prefix",
		"persistentHint",
		"clearable",
		"disabled",
		"type",
		"allowedDates",
	],
	data: () => ({
		menu: false,
		dataInternaEn: null,
		dataInternaPt: null,
	}),
	methods: {
		updateValue(v) {
			if (v == "") {
				v = null;
			}
			this.menu = false;
			this.$emit("input", v);
		},
	},
	watch: {
		value: function () {
			this.dataInternaEn = this.value;
			this.dataInternaPt = this.value.split("-").reverse().join("/");
		},
	},
	created() {
		this.dataInternaEn = this.value;
		this.dataInternaPt = this.value.split("-").reverse().join("/");
	},
};
</script>

<style scoped>
</style>

